import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { SaharahContext } from "@/contexts/Saharah"

import SaharahLayout from "@/components/SaharahLayout"
import FloorWallCard from "@/components/FloorWallCard"
import NoMatch from "@/components/NoMatch"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"

import createNodeId from "@/utils/createNodeId"

const SaharahFloorWallPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allSaharahYaml {
        nodes {
          rugs {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            sourceNote
          }
        }
      }
    }
  `)
  const { rugs } = queryData.allSaharahYaml.nodes[0]

  const formatRugs = rugs.map((rug) => ({
    ...rug,
    isCollected: false,
    id: createNodeId(rug.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  return (
    <SaharahLayout>
      <MultiLineTabs value={value} onChange={handleChange}>
        <TextTab label="all" />
        <TextTab label="large" />
        <TextTab label="medium" />
        <TextTab label="small" />
      </MultiLineTabs>
      <MultiLineTabs value={value2} onChange={handleChange2}>
        <TextTab label="all" />
        <TextTab label="notCollected" />
        <TextTab label="collected" />
      </MultiLineTabs>
      <FloorWallCards
        tabs={{ value, value2 }}
        rugs={formatRugs}
      />
    </SaharahLayout>
  )
}

export default SaharahFloorWallPage

const FloorWallCards = ({ rugs, tabs }) => {
  const { saharah } = useContext(SaharahContext)

  const currentRugs = useMemo(() => {
    const { value, value2 } = tabs
    const rugsHasMarked = rugs.map((rug) => {
      const rugHasMarked = saharah.filter((id) => id === rug.id)
      return {
        ...rug,
        isCollected: rugHasMarked.length ? true : false,
      }
    })
    let rugsArr = [...rugsHasMarked]

    if (value === 1) {
      rugsArr = rugsArr.filter((rug) => rug.sourceNote==="Large rug")
    } else if (value === 2) {
      rugsArr = rugsArr.filter((rug) => rug.sourceNote==="Medium rug")
    } else if (value === 3) {
      rugsArr = rugsArr.filter((rug) => rug.sourceNote==="Small rug")
    }

    if (value2 === 1) {
      rugsArr = rugsArr.filter((rug) => !rug.isCollected)
    } else if (value2 === 2) {
      rugsArr = rugsArr.filter((rug) => rug.isCollected)
    }

    return rugsArr
  }, [saharah, rugs, tabs])

  return (
    <StyledFloorWallCards>
      {currentRugs.length ? (
        <div className="rug_cards">
          {currentRugs.map((rug) => (
            <FloorWallCard floorwall={rug} key={rug.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledFloorWallCards>
  )
}

const StyledFloorWallCards = styled.div`
  .rug_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`